body {
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  unicode-bidi: isolate;
}

h1, h2, h3, h4, h5, h6{
  font-family: Avant Garde,Avantgarde,Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
}

html {
  font-size: 62.5%;
  color: rgb(50, 50, 50);
}

:root {
  --darkestblue: #001128;
  --darkblue: #0c3062;
}

.subTitles{
  width: calc(80 - 4rem);
  padding: 0 calc(10% + 2rem);
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 40%;
  }
}
