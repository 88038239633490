.adminTopBox{
    width: 100%;
    height: fit-content;
    position: relative;
}

.adminTopImg{
    width: 100%;
    min-height: 20rem;
    object-fit: cover;
}

.transBgBlack{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    background-color: rgba(0, 0, 0, 0.555);
    cursor: pointer;
    transition: 0.5s;
}

.transBgBlack:hover{
    background-color: rgba(0, 0, 0, 0.8);
}

.adminCard{
    position: relative;
}

.adminDel, .quillDel{
    width: fit-content;
    padding: 0.5rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: rgb(255, 80, 80);
    border: 3px solid  rgb(255, 80, 80);
    box-shadow: 0 0 1rem rgb(68, 27, 27);
    z-index: 100;
    border-radius: 1rem;
    transition: 0.5s;
    font-size: 3rem;
    cursor: pointer;
    color: white;
}

.adminDel:hover{
    background-color: brown;
    border: 3px solid brown;

}

.addBtn{
    width: calc(100% - 8rem);
    padding: 2rem;
    margin: 4rem;
    height: 20rem;
    font-size: 10rem;
    color: dimgray;
    background-color: white;
    border: 3px dashed dimgray;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
}

.addBtn:hover{
    background-color: rgb(199, 199, 199);
}

.commCardAdmin{
    width: calc(100% - 4rem);
    border-radius: 1rem;
    margin:2rem;
    overflow: hidden;
    box-shadow: 0 0 1rem ;
    font-size: 2rem;
    background-color: black;
    color: white;
}

.delComImg{
    width: 100%;
    object-fit: cover;
    height: 100%;
}

@media screen and (max-width:675px) {
    .quillDel{
        margin: 10rem 0 0 0;
    }
}