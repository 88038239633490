.innerContents{
    width: calc(80% - 4rem);
    margin: 0 auto;
    padding: 0 2rem;
    font-size: 2rem;
}

.innerContents h2{
    color: var(--darkblue);
}

.innerContents p{
    text-align: left;
}