.aboutContent{
    width: calc(80% - 10rem);
    margin: 0 10%;
    padding: 0 5rem;
    border-left: 3px solid var(--darkblue);
    font-size: 2rem;
}

.paragraph div p{
    text-align: left;
}

.aboutContent p{
    text-align: left;
}

@media only screen and (max-width:670px){
    .aboutContent .paragraph{
        font-size: 2.6rem;
    }
}