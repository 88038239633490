.mainOsBox{
    width: calc(100%);
    min-height: 100vh;
}

.osBg{
    width: 100%;
    height: 100vh;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.osTxt{
    width: fit-content;
    text-transform: uppercase;
    font-size: 2rem;
    max-width: 32rem;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 3rem black;
}