.subTitle{
    width: calc(80% - 4rem);
    padding: 0 calc(10% + 2rem);
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
}

.blogContainer{
    width: calc(80% - 4rem);
    padding: 0 calc(10% + 2rem);
    text-align: center;
}