.main {
  width: 100%;
  min-height: 100vh;
  text-align: left;
}

.mainSlide {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.slider {
  width: 300%;
  height: 100vh;
  position: absolute;
  display: flex;
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  25% {
    left: 0; /* Start with the first image */
  }
  33.33%,
  58.33% {
    left: -100%; /* Move to the second image */
  }
  66.67%,
  91.67% {
    left: -200%; /* Move to the third image */
  }
  100% {
    left: 0; /* Slide back to the first image */
  }
}
.slidingImage {
  width: calc(100% / 3);
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slideTitle {
  color: var(--darkblue);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  text-transform: uppercase;
}

.expContainer {
  width: calc(100% - 40px);
  padding: 40px 20px;
  background-color: var(--darkblue);
  font-size: 1.6rem;
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-weight: 500;
}

.expContainer div {
  color: white;
  text-transform: uppercase;
  justify-content: center;
  text-align: center;
}

.expContainer div h1 {
  text-transform: none;
  font-size: 5rem;
  margin: 10px 0;
}

.expContainer div p {
  margin: 0;
  padding: 0;
  width: 100%;
}

.step {
  height: 10rem;
}

.Serv {
  width: 90%;
  padding: 5rem 5%;
  font-size: 1.8rem;
  text-align: center;
}

.Serv h1 {
  color: var(--darkblue);
}

.servHolder {
  width: 100%;
  gap: 500px;
}

.empInc{
  min-width: 30rem;
}

.seemore {
  width: 20rem;
  padding: 1.5rem 0;
  font-size: 2.2rem;
  color: var(--darkblue);
  border: 3px solid var(--darkblue);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
  margin: 5px;
}

.seemore:hover {
  background-color: var(--darkblue);
  color: white;
}

.abtHome {
  width: calc(80%);
  padding: 7.75rem 10%;
  background-color: aliceblue;
}

.abtHome center{
  font-size: 2rem;
  color: var(--darkblue);
}

.flexAbt{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.abouttext {
  width: calc(50% - 4rem);
  min-width: 32rem;
  padding: 2rem;
  font-size: 2em;
}

.homeImg {
  width: calc(50% - 4rem);
  max-width: 500px;
  min-width: 300px;
  margin: 2rem;
  min-height: 300px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.abouttext h1 {
  color: var(--darkblue);
}

.homeInnerContents {
  p {
    line-height: 3rem;
  }
  strong {
    color: var(--darkblue);
  }
}

.learn {
  font-size: 2rem;
  padding: 15px;
  color: white;
  background-color: var(--darkblue);
  border: 3px solid var(--darkblue);
  cursor: pointer;
  border-radius: 10px;
}

.industryhold {
  width: calc(100% - 40px);
  font-size: 1.8rem;
  background-color: rgb(230, 235, 241);
  text-align: center;
  padding: 5rem 20px;
}

.industryhold h1 {
  text-transform: uppercase;
  color: var(--darkblue);
}

.industries{
    width: 80%;
    text-align: center;
    padding: 0 10%;
}

@media only screen and (max-width:670px){
  .abouttext{
    width: 100%;
    padding: 2rem;
  }
  .Serv{
    width: calc(100% - 4rem);
    padding: 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .abtHome {
    width: calc(100% - 40px);
    padding: 5rem 20px;
  }
}

@media only screen and (max-width: 500px) {
  .expContainer {
    flex-direction: column;
    gap: 40px;
  }
}
