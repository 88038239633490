.navBox {
  width: calc(100% - 4rem);
  padding: 2rem;
  background-color: transparent;
  position: fixed;
  top: 0;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  z-index: 500;
}

.navbar {
  width: 50%;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.navbar .full {
  display: flex;
  gap: 2rem;
}

.unScolledLogo{
  height: 10rem;
  position: absolute;
  left: 4rem;
  top: 4rem;

}

.navbar .menu {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 3rem;
  position: fixed;
  right: 20px;
  text-align: right;
  width: 4rem;
  display: none;
}



.logo {
  height: 5rem;
}

.nav {
  width: fit-content;
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  font-size: 2.4rem;
  margin: 0.75rem 2rem;
}

.navlink {
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.5s;
  white-space: nowrap;
  padding-top: 2px;
}

.navlink:hover {
  color: orange;
}

.scrolled {
  background-color: var(--darkestblue);
  transition: 0.5s;
}

.login {
  font-size: 2.4rem;
  padding: 1.2rem;
  color: white;
  background-color: transparent;
  margin-left: 2rem;
  margin-right: 5rem;
  border: none;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 1rem;
}

.login:hover {
  background-color: orange;
}

.menuHolding{
  width: fit-content;
  position: absolute;
  padding-top: 3.2rem;
  text-align: left;
}

.menus {
    width: 20rem;
    right: 0;
    display: none;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.75);
    font-weight: 600;
    box-shadow: 0 0 1rem rgba(207, 207, 207, 0.322);
    color: var(--darkestblue);
    text-align: left;
}

.topMenu{
  top: unset;
  left: unset;
  right: unset;
  width: 300px;
}

.topMenu a, .topMenu button{
  padding: 20px 40px ;
  border: none;
  cursor: pointer;
  transition: 0.5s;
  color:rgb(54, 61, 73);
  text-decoration: none;
  
}

.topMenu a:hover, .topMenu button:hover{
  background-color: rgba(0, 0, 0, 0.171);
}

.sublink{
    width: (calc(100% - 4rem));
    padding: 1.5rem 2rem;
    color: var(--darkestblue);
    font-size: 2.2rem;
    text-transform: capitalize;
    text-decoration: none;
}

.navbar .menu:hover .menus{
    display: flex;
}

.mainMenu{
  background: transparent;
  font-size: 2.4rem;
  border: none;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  text-transform: capitalize;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif;
}
.mainMenu.menus{
  display: none;
}

.mainMenu:hover .menus{
  display: flex;
}

.mainMenu:hover{
  color: orange;
}

.usernav{
  width: calc(20rem);
  padding: 2rem;
  top: 0;
  height: 100%;
  left: 0;
  position: fixed;
  background-color: var(--darkestblue);
}

.usernav img{
  width: 7.5rem;
  margin: 20px auto;
}

.adminNavigation{
  width: 100%;
  padding: 0.75rem;
  font-size: 1.8rem;
  color: white;
  border: none;
  background-color: transparent;
  font-weight: 600;
  margin: 1rem 0;
  font-family: sans-serif;
  justify-content: center;
}

.useLink{
  text-decoration: none;
}

.useLink.active button{
  background-color: rgba(0, 0, 0, 0.274);
}

.topMenu{
  text-align: left;
}

@media only screen and (max-width: 1250px) {
  .navbar {
    width: fit-content;
  }
}

.sublink{
  text-align: left;
}

@media only screen and (max-width: 700px) {
  .navbar {
    width: calc(100% - 40px);
    padding: 0 2rem;
  }
  .navbar .full {
    display: none;
  }
  .navbar .menu {
    display: block;
  }.menuHolding{
    right: 0;
  }
}
