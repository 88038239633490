.footer{
    width: calc(100% - 8rem);
    display: flex;
    padding: 4rem;
    background-color: var(--darkestblue);
    color: white;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footing{
    max-width: 30rem;
    min-width: 20rem;
    padding: 2rem;
    font-size: 1.6rem;
    gap: 2rem;
}

.footing a{
    text-decoration: none;
    color: white;
    font-size: 1.6rem;
    height: fit-content;
}

.footLinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
}

.socialLink{
    font-size: 3rem;
}

.profFoot{
    display: none;
}