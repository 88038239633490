.SlideEditor {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.imageContainer {
  width: calc(100% / 3);
  height: 30rem;
  position: relative;
}

.bgColor {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  cursor: pointer;
}

.bgColor:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.5s;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.change {
  padding: 1.5rem;
  border-radius: 10px;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-weight: 600;
  transition: 0.5s;
  border: 0.3rem solid;
}

.change:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.urls {
  padding: 1rem 1.5rem;
  position: absolute;
  background: transparent;
  color: white;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  margin: 1rem;
  right: 1rem;
  border: 2px dashed;
  border-radius: 2rem;
  cursor: pointer;
}

.urls:hover {
  color: rgb(0, 183, 255);
}

.formContainer {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.712);
  transition: 0.5s;
}

.urlForm {
  background-color: white;
  width: calc(100% - 8rem);
  padding: 3rem;
  position: absolute;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ExpEditor {
  width: calc(100% - 6rem);
  padding: 3rem;
  background-color: var(--darkblue);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5rem;
}

.adminInput {
  padding: 2rem;
  background-color: transparent;
  border: none;
}

.EditHomeAbt {
  width: calc(100% - 2rem);
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25rem 0;
}

.abtHomeBoxEdit{
    width: calc(50% - 2rem);
    padding: 1rem;
    min-width: 20rem ;
}

.imageOfEditor {
  height: 50rem;
  width: 25rem;
  object-fit: cover;
}

.isimage{
   width: 100%;
   height: 100%;
   min-height: 40rem;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: 1rem;
   overflow: hidden;
   position: relative;
   cursor: pointer;
}

.absBg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

.absBg:hover{
    background-color: rgba(0, 0, 0, 0.8);
}

.changeImg{
    color: white;
    font-size: 4rem;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {
  .imageContainer {
    width: 100%;
  }
}
