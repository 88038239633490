.changeAbtBox{
    width: calc(100% - 4rem);
    padding: 2rem;
}

.abtAdminInput{
    width: calc(100% - 4rem);
    padding: 1rem;
    margin: 1rem;
    font-size: 2rem;
}

.btnAdd{
    margin: 0 1rem;
    padding: 1.5rem;
    background-color: var(--darkblue);
    color: white;
    border: 3px solid var(--darkblue);
    border-radius: 2rem;
}

.search{
    width: calc(100% - 2rem);
    padding: 1rem;
    font-size: 2rem;
    border-radius: 0.5rem;
}

.contactInfo{
    width: calc(100% - 4rem);
    padding: 2rem;
    box-shadow: 0 0 1rem black;
    border-radius: 1rem;
    overflow-x: scroll;
}

.contactInfo::-webkit-scrollbar{
    height: 0;
}