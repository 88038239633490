.userInfo{
    width: calc(100% - 8rem);
    padding: 2rem;
    margin: 3rem 2rem;
    font-size: 2rem;
    color: rgb(73, 73, 73);
    box-shadow:  0 0 0.5rem black;
}

.userDataHolding{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.profileImage{
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 3.5rem;
    padding: 2rem;
    background-color:rgb(16, 108, 212);
    font-weight: 500;
    color: white;
    border-radius: 50%;
}

.userDataHolding font{
    font-size: 2.5rem;
    font-weight: 500;
    padding: 2.2rem;
}