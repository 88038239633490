.enterTitle{
    width: calc(100% - 4rem);
    font-size: 5rem;
    padding: 1rem;
    margin: 1rem;
    resize: none;
    font-family: sans-serif;
}

.mainQuill{
    width: calc(100% - 28rem);
    padding: 2rem;
    float: right;
}