.changePwd{
    width: calc(100% - 8rem);
    max-width: 30rem;
    font-size: 1.6rem;
    margin: 2rem;
    padding: 2rem;
    box-shadow: 0 0 1rem var(--darkblue);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3rem;
    color: gray;
}

