.servCards {
  width: 30rem;
  height: 42rem;
  overflow: hidden;
  box-shadow: 0 0 2px var(--darkblue);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--darkblue);
  transition: 0.5s;
  display: inline-block;
  color: white;
  text-align: left;
  font-size: 1.6rem;
}

.imageHolderServ {
  width: 100%;
  height: 30rem;
  overflow: hidden;
}

.servImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.servCards:hover .servImg {
  transform: scale(1.1);
}

.servCards:hover {
  box-shadow: 0 0 10px var(--darkblue);
}

.textOfServ {
  width: calc(100% - 40px);
  position: relative;
  padding: 0 20px;
  height: 15rem;
  overflow: hidden;
}

.textOfServ font{
  width: calc(100% - 40px);
  position: absolute;
  top: 50%;
  padding-bottom: 3rem;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 3rem;
  font-weight: 600;
  font-size: 1.6rem;
}

.industryCard {
  background-color: transparent;
  width: calc(100% - 60px);
  max-width: 400px;
  padding: 20px;
  height: 150px;
  display: inline-block;
  margin: 10px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 5px rgb(212, 212, 212);
  transition: 0.5s;
  color: var(--darkblue);
  font-size: 1.8rem;
  position: relative;
}

.contentOfCard {
  width: calc(100% - 20px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.industryCard:hover {
  border: 2px solid var(--darkblue);
}
.icon {
  font-size: 3rem;
}
.TopBox {
  width: 100%;
}

.TopBox img {
  width: 100%;
}

.pageTitle {
  width: calc(80% - 40px);
  margin: 20px 10%;
  padding: 20px;
  font-size: 2rem;
  line-height: 30px;
  color: var(--darkblue);
}

.paragraph {
  font-size: 2rem;
  line-height: 3rem;
  padding: 0;
  position: relative;
}

.paragraph h3 {
  color: var(--darkblue);
}

.paragraph div p {
  text-align: left;
}

.paragraph div ul {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.paragraph div ul li {
  padding: 1rem 0;
  list-style: none;
}

.paragraph div ul li::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: var(--darkblue);
  color: var(--darkblue);
  position: absolute;
  margin-top: 1rem;
  border-radius: 50%;
  transform: translateX(-5.65rem);
}

.paragraph h3::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--darkblue);
  color: var(--darkblue);
  position: absolute;
  transform: translateX(-5.93rem);
}

.commCard {
  width: calc(100%);
  margin: 4rem 0;
  position: relative;
  overflow: hidden;
  height: 62rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.commCard::-webkit-scrollbar {
  position: absolute;
  right: 0;
  width: 5px;
  background-color: black;
}

.commCard::-webkit-scrollbar-track {
  position: absolute;
  right: 0;
  width: 5px;
  background-color: black;
}

.commCard::-webkit-scrollbar-thumb {
  background-color: rgb(133, 133, 133);
  width: 5px;
  border-radius: 10px;
}

.danger a{
  color: white;
  text-decoration: none;
}

.commContent {
  width: calc(70%);
  padding: 3rem 15%;
  height: fit-content;
  min-height: 56rem;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 38rem;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  font-size: 2rem;
}

.commCard:hover {
  overflow-y: scroll;
}

.commCard:hover .commContent {
  top: 0;
}

.commContent div {
  line-height: 3rem;
  padding-top: 0;
  text-align: left;
}

.gridControllers {
  width: fit-content;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.grid {
  font-size: 3rem;
  background-color: transparent;
  border: none;
  margin: 2rem;
  color: darkgray;
  cursor: pointer;
}

.grid:hover {
  color: dimgray;
}

.activeGrid {
  color: black;
}

.activeGrid:hover {
  color: black;
}

.blogListC {
  width: 100%;
  height: 50rem;
  display: flex;
  box-shadow: 0 0 10px gray;
  border-radius: 5px;
  overflow: hidden;
  margin: 5rem 0;
}

.blogGridC {
  width: 35rem;
  height: fit-content;
  box-shadow: 0 0 10px gray;
  border-radius: 5px;
  margin: 2rem;
  display: inline-block;
  vertical-align: top;
  height: 70rem;
}

.blogGridC img {
  width: 100%;
  height: 30rem;
  object-fit: cover;
}

.blogGridC .blogCardContent {
  width: calc(100% - 4rem);
  padding: 2rem;
  text-align: left;
  font-size: 1.6rem;
  color: dimgray;
}

.blogGridC .blogCardContent p {
  text-align: left;
}

.blogGridC .blogCardContent button {
  font-size: 1.6rem;
}

.blogListC img {
  width: 60%;
  height: 100%;
  object-fit: cover;
}

.blogListC .blogCardContent {
  width: calc(40% - 8rem);
  padding: 4rem;
  font-size: 2rem;
  color: dimgray;
  text-align: left;
}

.blogDate {
  color: orange;
}

.readMore {
  padding: 1.5rem;
  color: var(--darkblue);
  background-color: transparent;
  border: 2px solid var(--darkblue);
  border-radius: 5px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 10px;
}

.readMore:hover {
  color: white;
  background-color: var(--darkblue);
}

.goback {
  font-size: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
}

.embededContent iframe {
  width: 100%;
  height: 60rem;
}

.loginMain {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.loginMain .img {
  width: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.mainLoginForm{
  width: calc(50% - 4rem);
  padding: 2rem;
  min-width: fit-content;
  position: relative;
}

.loginForm {
  max-width: calc(50rem);
  min-width: calc(30rem);
  font-size: 2rem;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginInput {
  width: calc(100% - 3rem);
  padding: 1.25rem;
  font-size: 2rem;
  margin: 1rem 0;
  border: 1px solid gray;
}

.loginForm h1, .loginForm h3{
  color: var(--darkblue);
}

.reg{
  width: calc(100%);
  padding: 1.5rem 1rem;
  cursor: pointer;
  border: 1px solid var(--darkblue);
  background-color: white;
  font-size: 2rem;
  color: var(--darkblue);
  border-radius: 5px;
}

.workCard{
  width: calc(100% - 4rem);
  padding:3rem 2rem;
  background: white;
  margin: 5rem 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.workCard:nth-child(even){
  flex-direction: row-reverse;
}

.imageholderWrk{
  width: calc(40% - 4rem);
  min-width: 30rem;
  padding: 2rem;
  position: relative;
}

.workShopImage{
  width: 100%;
  height: 100%;
  min-height: 30rem;
  object-fit: cover;
  box-shadow: 0 0 1rem ;

}

.contentWorkShop{
  width: calc(40% - 4rem);
  min-width: 30rem;
  padding: 2rem;
  font-size: 1.8rem;
}

.redirector{
  width: 20rem;
  padding: 1.5rem;
  background-color: rgb(255, 136, 0);
  color: white;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
}

.contactTopBox{
  padding: 0 5.5rem;
}

.innerContents, .innerContents p{
  font-size: 2.4rem;
}

@media only screen and (max-width: 750px) {
  .commContent {
    width: calc(100% - 4rem);
    padding: 2rem;
    font-size: 1.8rem;
    top: 40rem;
  }
  .blogListC {
    display: block;
    height: fit-content;
  }
  .blogListC img {
    width: 100%;
    height: 30rem;
  }
  .blogListC .blogCardContent {
    width: calc(100% - 6rem);
    padding: 3rem;
  }
  .grid {
    display: none;
  }
  .loginForm{
    width: 30rem;
    position: unset;
    top: 0;
    left: 0;
    transform: none;
    margin: 0 auto;
  }
  .mainLoginForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.commBr{
  display: none;
}

@media only screen and (max-width:670px){
  .servCards{
    width: calc(100% - 4rem);
    margin: 2rem;
    height: 300px;
  }

  .textOfServ font{
    font-size: 2rem;
    padding-bottom: 0;
  }.workCard{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .pageTitle h1{
    margin: 0;
    padding: 0;
  }.pageTitle{
    padding-left: 0;
  }
  .commBr{
    display: block;
  }
  .commContent{
    position: absolute;
    min-height: calc(100% - 4rem);
  }
}