.contactContent {
  width: calc(80%);
  padding:0 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 4rem;
}

.contactForm {
  width: calc(100% - 4rem);
  max-width: 60rem;
  padding: 0 2rem;
  padding-bottom: 3rem;
  font-size: 1.8rem;
}

.contactForm h1{
    color: var(--darkblue);
}

.inputBoxes {
  width: 100%;
  font-size: 2rem;
  padding: 1.5rem 0;
  font-weight: 500;
  text-transform: capitalize;
}

.input {
  width: calc(100% - 2rem);
  padding: 1rem;
  font-size: 1.8rem;
  resize: none;
  border: 1px solid dimgray;
}

#note {
  height: 20rem;
}

.submit {
  padding: 1rem;
  background-color: var(--darkblue);
  color: white;
  font-size: 1.8rem;
  border: 3px solid var(--darkblue);
  cursor: pointer;
  border-radius: 0.25rem;
}

.embededmap {
  width: 100%;
  max-width: 64rem;
}

.embededmap iframe {
  width: 100%;
  height: 80%;
  padding: 10% 0;
}
