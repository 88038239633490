.workHolder{
    width: calc(80% - 4rem);
    padding: 0 calc(10% + 2rem);
}

@media only screen and (max-width: 670px){
    .workHolder{
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .workshopMain, .workHolder{
        width: 100%;
        margin: 0;
    }.workCard{
        width: calc(100% - 16rem);
        padding: 0 8rem 4rem 8rem;
    }
    .imageholderWrk{
        width: 100%;
        padding: 0;
        margin: 2rem 0;
    }
    .contentWorkShop{
        width: 100%;
        padding: 0;
    }
}