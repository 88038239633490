.mainProf{
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.userProfBox{
    width: calc(100% - 28rem);
    padding: 2rem;
    font-size: 2rem;
}

.userProfBox{
    color: var(--darkblue);
}

.profileHolder{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.profIndetifier{
    width: 7rem;
    padding: 2rem;
    font-size: 3rem;
    border-radius: 5rem;
    color: white;
    background-color: rgb(66, 94, 255);
    border: none;
    cursor: pointer;
    transition: 0.5s;
    text-transform: capitalize;
    position: relative;
    height: fit-content;
}

.profControl{
    position: absolute;
    top: 0rem;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    color: dimgray;
    border-radius: 1rem;
    display: none;
}

.buttonsHolderOfProf{
    margin-top: 10rem;
    padding: 2rem;
    box-shadow: 0 0 1rem rgb(117, 117, 117);
    background-color: white;
    border-radius: 01rem;
    text-align: left;
    text-transform: none;
    cursor: default;
    width: fit-content;
    right: 0;
}

.logout{
    background-color: rgb(66, 142, 255);
    border: none;
    padding: 2rem;
    color: white;
    border-radius: 1rem;
    cursor: pointer;
    transition: 0.5s;
}

.userProfBox iframe{
    width: 100%;
}

.logout:hover{
    background-color: rgb(29, 71, 134);
}

.profIndetifier:hover{
    box-shadow: 0 0 1rem black;
}

.profIndetifier:hover .profControl{
    display: block;
}

.embedInput{
    width: calc(100% - 4rem);
    padding: 2rem;
    font-size: 2rem;
    resize: none;
}

.iframe{
    height: 500px;
    width: calc(100% - 4rem);
    object-fit: contain;
    overflow: scroll;
}