.adminEdit{
    width: calc(100% - 20rem - 4rem);
    padding: 0rem;
    position: absolute;
    right: 0;
    height: fit-content;
}

.footerEdit{
    width: fit-content;
    padding: 2rem;
    font-size: 2rem;
    color: var(--darkblue);
    margin: 0 auto;
}

.inputTitle{
    width: calc(100% - 4rem);
    padding: 2rem;
    font-size: 5rem;
    border: 1px solid gray;
}